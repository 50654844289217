import Markdown from 'react-markdown'
import { useEffect, useState } from 'react';
import srb2Path from '../md/srb2.md'
import KartStatusPanel from '../components/kartstatus/KartStatusPanel';
import '../css/srb2kart.css';

function SRB2Kart () {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(srb2Path).then(res => res.text()).then(md => setMarkdown(md))
        }
    );

    return (
        <div>        
            <div className="embedded-status">
                <KartStatusPanel/>
                <div className="modalbox neu download-panel">
                <a
                    href="/resources/kart/SHRIKE KART 1.0.0pre.zip"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Download latest (full)</a>
                <a
                    href="/resources/kart/SHRIKE KART 1.0.0pre.zip"                    
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Download mods only</a>
            </div>
            </div>
            <div className='modalbox neu article-body'>
                <Markdown children={markdown}/>
            </div>
        </div>

    );
} export default SRB2Kart;